import "./App.css";
import React from "react";
import { Routes, Route, Link } from "react-router-dom";
import BussinessWeb from "./BussinessWeb";
import { Helmet } from "react-helmet";
import TmpPayment from "./components/TmpPayment";

function App() {
  //設定<meta content="noIndex"，使dev環境不會被估狗搜尋到
  const robotsMeta = process.env.REACT_APP_ROBOTS_META || '';

  return (
    <>
      <Helmet>
        {robotsMeta === "noindex" && <meta name="robots" content="noindex" />}
      </Helmet>
      
      <Routes>
        <Route path="/" index element={<BussinessWeb />} />
       
        <Route path="*" element={<p>找不到頁面</p>} />
      </Routes>
    </>
  );
}

export default App;
