const Theme = {
    grid: {
        containerWidth: {
            xs: "540px",
            sm: "720px",
            md: "960px",
            lg: "1156px",
            xl: "1200px"
        },
        gutterWidth: "16px",
    },
    textSize: {
        size: {
            header: "20px",
            heading: "48px",
            heading1: "36px",
            heading2: "24px",
            body1: "16px",
            textInput: "16px",
            heading3: "18px",
            tablephone: "16px",
            subheading1: "16px",
            body2: "10px",

            reportBody1: "22px",
            reportBody2: "16px",
            reportBody3: "16px",
            reportHeading1: "22px",
            reportHeading2: "20px",
            reportHeading3: "16px",
            reportHeading4: "14px",
            reportHeading5: "12px",
            reportEngBac: "14px",
            reportPetName: "88px",
            reportTag: "18px",
            reportCompareFont: "30px",
            reportComparePercent: "35px",
        },
        height: {
            header: "160%",
            heading: "80px",
            heading1: "auto",
            heading2: "auto",
            body1: "auto",
            textInput: "24px",
            heading3: "auto",
            tablephone: "16px",
            subheading1: "24px",
            body2: "16px",

            reportBody1: "36px",
            reportBody2: "24px",
            reportBody3: "auto",
            reportHeading1: "auto",
            reportHeading2: "160%",
            reportHeading3: "160%",
            reportHeading4: "auto",
            reportHeading5: "160%",
            reportEngBac: "auto",
            reportPetName: "auto",
            reportTag: "24px",
            reportCompareFont: "36px",
            reportComparePercent: "36px",

        },
    }
};

export default Theme