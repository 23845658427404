import React, { useEffect, useState } from "react";
import { Col, Div, Image, Row, Text, ThemeProvider } from "atomize";
import Logo from "../assets/images/PetSci_Logo_horizontal.png";
import Menu from "../assets/icon/menu.png";
import { ReactComponent as PetsPurple } from "../assets/icon/pets_purple.svg";
import Btn from "./Btn";

const theme = {
  grid: {
    containerWidth: {
      xs: "540px",
      sm: "720px",
      md: "960px",
      lg: "1156px",
      xl: "1156px",
    },
    gutterWidth: "16px",
  },
  textSize: {
    size: {
      heading1: "24px",
      header: "20px",
      body1: "16px",
      textInput: "16px",
    },
    height: {
      heading1: "auto",
      header: "160%",
      body1: "auto",
      textInput: "24px",
    },
  },
};

const useRWD = () => {
  const [navBarIcon, setNavBarIcon] = useState("mobile");

  const handleRWD = () => {
    if (window.innerWidth > 768) setNavBarIcon("text");
    else setNavBarIcon("Icon");
  };

  useEffect(() => {
    window.addEventListener("resize", handleRWD);
    handleRWD();
    return () => {
      window.removeEventListener("resize", handleRWD);
    };
  }, []);

  return navBarIcon;
};

const Item = ({ text }) => (
  <Div d="flex" justify="center" align="center">
    <PetsPurple />
    <Text
      textSize="reportHeading2"
      textWeight="900"
      textColor="#79384A"
      m={{ l: "0.25rem" }}
    >
      {text}
    </Text>
  </Div>
);

const NavBar = ({ logoClick, techClick, reportsClick, contactClick }) => {
  const [isMenuOpen, setMenuOpen] = useState(false);
  const [menuIconRotated, setMenuIconRotated] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
    setMenuIconRotated(!menuIconRotated);
  };
  const handleClick = () => {
      setMenuOpen(false);
      setMenuIconRotated(false);
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      // 假设您的菜单图标和导航内容有一个共同的父元素，其id为"navbar"
      if (!document.getElementById("navbar").contains(event.target)) {
        setMenuOpen(false); // 如果点击事件不是发生在导航栏内部，则收起导航栏
        setMenuIconRotated(false);
      }
    };

    if (isMenuOpen) {
      // 如果导航栏是展开的，才监听点击事件
      window.addEventListener("click", handleOutsideClick);
    }

    // 清理函数，用于组件卸载时移除事件监听
    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [isMenuOpen]);

  useEffect(() => {
    const handleResize = () => {
      setMenuOpen(false);
      setMenuIconRotated(false);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const navBarIcon = useRWD();

  return (
    <ThemeProvider theme={theme}>
      <Div d="flex" align="center" pos="relative" zIndex="99" id="navbar">
        <Row>
          <Col size={{ xs: 12, lg: 12 }}>
            <Div
              d="flex"
              flexDir="column"
              pos="fixed"
              bg="#ffffff"
              w="100%"
              p={{
                y: "1rem",
                x: {
                  xs: "1rem",
                  md: "3.5rem",
                  lg: "4.5rem",
                },
              }}
            >
              <Div d="flex" flexDir="row" justify="space-between">
                <Div d="flex" align="center">
                  <Image src={Logo} w="7.5rem" onClick={logoClick} />
                </Div>
                {/* 電腦版 */}
                {navBarIcon === "text" ? (
                  <Div d="flex" flexDir="row" justify="flex-end">
                    <Div
                      m={{ r: "1.5rem" }}
                      cursor="pointer"
                      onClick={techClick}
                    >
                      <Item text="核心技術" />
                    </Div>

                    <Div
                      m={{ r: "1.5rem" }}
                      cursor="pointer"
                      onClick={reportsClick}
                    >
                      <Item text="顧客回饋" />
                    </Div>

                    <Div
                      m={{ r: "1.5rem" }}
                      cursor="pointer"
                      onClick={() =>
                        window.open("https://gogomaumau.petsci.tw/", "_blank")
                      }
                    >
                      <Item text="查看報告" />
                    </Div>

                    <Div
                      m={{ r: "1.5rem" }}
                      cursor="pointer"
                      onClick={contactClick}
                    >
                      <Item text="聯絡我們" />
                    </Div>

                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.zeczec.com/projects/petsci-gut-mamoth/?utm_source=website&utm_medium=website&utm_campaign=zec",
                          "_blank"
                        )
                      }
                    >
                      <Btn text="產品介紹" />
                    </Div>
                  </Div>
                ) : (
                  <Div d="flex" flexDir="row" align="center">
                    <Image
                      src={Menu}
                      h="24px"
                      w="24px"
                      onClick={toggleMenu}
                      transition
                      style={{
                        transform: menuIconRotated ? "rotate(90deg)" : "none",
                      }}
                    />
                  </Div>
                )}
              </Div>

              {/* 手機版 */}
              {isMenuOpen && (
                <Div
                  w="100%"
                  h="auto"
                  d="flex"
                  flexDir="column"
                  p={{ x: "5.5rem", y: "1.25rem" }}
                  bg="#FFFFFF"
                  justify="center"
                  align="center"
                  style={{
                    position: "absolute",
                    zIndex: 1000,
                    right: "0",
                    top: "60px",
                  }} // 添加定位样式
                >
                  <Div onClick={handleClick}>
                    <Div onClick={techClick} cursor="pointer">
                      <Item text="核心技術" />
                    </Div>
                  </Div>

                  <Div onClick={handleClick}>
                    <Div onClick={reportsClick} cursor="pointer">
                      <Item text="顧客回饋" />
                    </Div>
                  </Div>

                  <Div onClick={handleClick}>
                    <Div
                      onClick={() =>
                        window.open("https://gogomaumau.petsci.tw/", "_blank")
                      }
                      cursor="pointer"
                    >
                      <Item text="查看報告" />
                    </Div>
                  </Div>

                  <Div onClick={handleClick}>
                    <Div onClick={contactClick} cursor="pointer">
                      <Item text="聯絡我們" />
                    </Div>
                  </Div>

                  <Div onClick={handleClick}>
                    <Div
                      d="flex"
                      justify="center"
                      align="center"
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.zeczec.com/projects/petsci-gut-mamoth/?utm_source=website&utm_medium=website&utm_campaign=zec",
                          "_blank"
                        )
                      }
                    >
                      <Btn text="產品介紹" />
                    </Div>
                  </Div>
                
                </Div>
              )}
            </Div>
          </Col>
        </Row>
      </Div>
    </ThemeProvider>
  );
};

export default NavBar;
