import { Modal, Icon, Div } from "atomize";
import React, { useState } from "react";

//modal內容
//children 為modal中顯示的內容
const ModalSize = ({ isOpen, onClose, children }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      align={{ xs: "start", md: "center" }}
      rounded="md"
      maxH={{ xs: "auto", md: "70vh" }}
      maxW="48rem"
      overflow="auto"
    >
      <Icon
        name="Cross"
        pos="absolute"
        top="1rem"
        right="1rem"
        size="16px"
        onClick={onClose}
        cursor="pointer"
      />
      <Div d="flex" m={{ b: "4rem" }}>
        {children}
      </Div>
    </Modal>
  );
};

//引用的組件
// icon 設定觸發點擊的標的
// chrildren 為react特殊的props，放置在<SmallModal></SmallModal>中都是children，被傳遞回components中
const SmallModal = ({ object, children }) => {
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <Div cursor="pointer" onClick={() => setShowModal(true)}>
        {object}
      </Div>

      <ModalSize
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        children={children}
      />
    </>
  );
};

export default SmallModal;
