import { Col, Div, Icon, Row, Text } from "atomize";
import React from "react";
import { ReactComponent as Medium } from "../assets/icon/medium.svg";
import { ReactComponent as Privacy } from "../assets/icon/privacy.svg";
import SmallModal from "./SmallModal";

const Footer = ({ techClick, reportsClick, contactClick }) => {
  return (
    <Div bg="#79384A">
      <Row
        p={{
          b: { xs: "6.5rem", xl: "5.5rem" },
          x: { xs: "2rem", xl: "14.25rem" },
          y: { xs: "3.5rem", xl: "5.5rem" },
        }}
      >
        <Col size={{ xs: 12, md: 4, lg: 4 }}>
          <Div
            d="flex"
            justify={{ xs: "flex-start", md: "center", lg: "center" }}
            align="center"
          >
            <Div>
              <Div d="flex" flexDir="column">
                <Text textSize="heading2" textWeight="600" textColor="#ffffff">
                  PetSci Co., Ltd.
                </Text>
                <Text
                  textSize="heading2"
                  textWeight="600"
                  textColor="#ffffff"
                  m={{ t: "0.5rem" }}
                >
                  科學毛怪生物資訊科技(股)公司
                </Text>
              </Div>
              <Div
                d="flex"
                flexDir="column"
                m={{ t: { xs: "0rem", lg: "1rem" } }}
              >
                <Text textSize="body1" textWeight="600" textColor="#ffffff">
                  service@petsci.tw
                </Text>

                <Text
                  textSize="body1"
                  textWeight="600"
                  textColor="#ffffff"
                  m={{ t: "0.6rem", b: "0.6rem" }}
                >
                  新北辦公室： 241 新北市三重區重新路一段 108 號 3 樓
                  (新北創立坊)
                </Text>

                <Text textSize="body1" textWeight="600" textColor="#ffffff">
                  公司地址： 428 台中市大雅區科雅路 6 號
                  (中科智慧機器人自造基地)
                </Text>

                <Text
                  textSize="body1"
                  textWeight="600"
                  textColor="#ffffff"
                  m={{ t: "0.6rem" }}
                >
                  Unified Business No. 29183464
                </Text>
              </Div>
            </Div>
          </Div>
        </Col>

        <Col size={{ xs: 12, md: 4, lg: 4 }}>
          <Div
            d="flex"
            justify={{ xs: "flex-start", md: "center", lg: "center" }}
            align="center"
          >
            <Div m={{ t: { xs: "1rem", md: "0rem", lg: "0rem" } }}>
              <Text textSize="header" textWeight="600" textColor="#ffffff">
                MENU
              </Text>
              <Row>
                <Col size="12">
                  <Div
                    d="flex"
                    flexDir={{
                      xs: "column",
                      md: "column",
                      lg: "column",
                      xl: "column",
                    }}
                  >
                    <Text
                      textSize="body1"
                      textWeight="400"
                      textColor="#ffffff"
                      m={{ t: "1rem", r: "0.5rem" }}
                      onClick={techClick}
                      cursor="pointer"
                    >
                      核心技術
                    </Text>
                    <Text
                      textSize="body1"
                      textWeight="400"
                      textColor="#ffffff"
                      m={{ t: "0rem", r: "0.5rem" }}
                      onClick={reportsClick}
                      cursor="pointer"
                    >
                      顧客回饋
                    </Text>
                    <Text
                      textSize="body1"
                      textWeight="400"
                      textColor="#ffffff"
                      m={{ t: "0rem", r: "0.5rem" }}
                      onClick={() =>
                        window.open("https://gogomaumau.petsci.tw/", "_blank")
                      }
                      cursor="pointer"
                    >
                      查看報告
                    </Text>
                    <Text
                      textSize="body1"
                      textWeight="400"
                      textColor="#ffffff"
                      m={{ t: "0rem", r: "0.5rem" }}
                      onClick={contactClick}
                      cursor="pointer"
                    >
                      聯絡我們
                    </Text>
                    <Text
                      textSize="body1"
                      textWeight="400"
                      textColor="#ffffff"
                      m={{ t: "0rem", r: "0.5rem" }}
                      cursor="pointer"
                      onClick={() =>
                        window.open(
                          "https://www.zeczec.com/projects/petsci-gut-mamoth/?utm_source=website&utm_medium=website&utm_campaign=zec",
                          "_blank"
                        )
                      }
                    >
                      申請檢測
                    </Text>
                  </Div>
                </Col>
              </Row>
            </Div>
          </Div>
        </Col>

        <Col size={{ xs: 12, md: 4, lg: 4 }}>
          <Div d="flex" justify="flex-start" align="center">
            <Div m={{ t: { xs: "1rem", md: "0rem", lg: "0rem" } }}>
              <Text textSize="header" textWeight="600" textColor="#ffffff">
                About
              </Text>
              <Row>
                <Col size="12">
                  <Div
                    d="flex"
                    flexDir="row"
                    m={{ t: "1rem" }}
                    justify="center"
                    align="center"
                  >
                    <a
                      href="https://www.instagram.com/petsci_tw/?utm_source=Bussiness+Web&utm_medium=Bussiness+Web&utm_campaign=Bussiness+Web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        name="Instagram"
                        size="24px"
                        color="#ffffff"
                        m={{ r: "1rem" }}
                      />
                    </a>

                    <a
                      href="https://www.facebook.com/profile.php?id=100093557151849&utm_source=Bussiness+Web&utm_medium=Bussiness+Web&utm_campaign=Bussiness+Web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon
                        name="Facebook"
                        size="24px"
                        color="#ffffff"
                        m={{ r: "1rem" }}
                      />
                    </a>

                    <a
                      href="https://medium.com/@petsci?utm_source=Bussiness+Web&utm_medium=Bussiness+Web&utm_campaign=Bussiness+Web"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Div m={{ r: "1rem" }}>
                        <Medium />
                      </Div>
                    </a>

                    <SmallModal object={<Privacy />}>
                      <Div
                        d="flex"
                        flexDir="column"
                        justify="center"
                        align="center"
                      >
                        <Text
                          textSize="heading2"
                          textWeight="600"
                          textAlign="center"
                          m={{ t: "0rem", b: "1.5rem" }}
                        >
                          隱私條款
                        </Text>
                        <Div>
                          歡迎您光臨 科學毛怪
                          PetSci（以下簡稱本網站），為了讓您能夠安心的使用本網站的各項服務與資訊，特此向您說明本網站的隱私權保護政策，以保障您的權益，請您詳閱下列內容：
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            一、隱私權保護政策的適用範圍
                          </Text>
                          <Text>
                            隱私權保護政策內容，包括本網站如何處理在您使用網站服務時收集到的個人識別資料。隱私權保護政策不適用於本網站以外的相關連結網站，也不適用於非本網站所委託或參與管理的人員。
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            二、個人資料的蒐集、處理及利用方式
                          </Text>
                          <Text m={{ l: "1rem" }}>
                            <ul
                              style={{
                                listStylePosition: "outside",
                                paddingLeft: "0px",
                              }}
                            >
                              <li>
                                當您造訪本網站或使用本網站所提供之功能服務時，我們將視該服務功能性質，請您提供必要的個人資料，並在該特定目的範圍內處理及利用您的個人資料；非經您書面同意，本網站不會將個人資料用於其他用途。
                              </li>
                              <li>
                                本網站在您使用服務信箱、問卷調查等互動性功能時，會保留您所提供的姓名、電子郵件地址、聯絡方式及使用時間等。
                              </li>
                              <li>
                                於一般瀏覽時，伺服器會自行記錄相關行徑，包括您使用連線設備的IP位址、使用時間、使用的瀏覽器、瀏覽及點選資料記錄等，做為我們增進網站服務的參考依據，此記錄為內部應用，決不對外公佈。
                              </li>
                              <li>
                                為提供精確的服務，我們會將收集的問卷調查內容進行統計與分析，分析結果之統計數據或說明文字呈現，除供內部研究外，我們會視需要公佈統計數據及說明文字，但不涉及特定個人之資料。
                              </li>
                              <li>
                                您可以隨時向我們提出請求，以更正或刪除本網站所蒐集您錯誤或不完整的個人資料。
                              </li>
                            </ul>
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            三、資料之保護
                          </Text>
                          <Text m={{ l: "1rem" }}>
                            <ul
                              style={{
                                listStylePosition: "outside",
                                paddingLeft: "0px",
                              }}
                            >
                              <li>
                                本網站主機均設有防火牆、防毒系統等相關的各項資訊安全設備及必要的安全防護措施，加以保護網站及您的個人資料採用嚴格的保護措施，只由經過授權的人員才能接觸您的個人資料，相關處理人員皆簽有保密合約，如有違反保密義務者，將會受到相關的法律處分。
                              </li>
                              <li>
                                如因業務需要有必要委託其他單位提供服務時，本網站亦會嚴格要求其遵守保密義務，並且採取必要檢查程序以確定其將確實遵守。
                              </li>
                            </ul>
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            四、網站對外的相關連結
                          </Text>
                          <Text>
                            本網站的網頁提供其他網站的網路連結，您也可經由本網站所提供的連結，點選進入其他網站。但該連結網站不適用本網站的隱私權保護政策，您必須參考該連結網站中的隱私權保護政策。
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            五、與第三人共用個人資料之政策
                          </Text>
                          <Text>
                            本網站絕不會提供、交換、出租或出售任何您的個人資料給其他個人、團體、私人企業或公務機關，但有法律依據或合約義務者，不在此限。
                          </Text>
                          <Text>前項但書之情形包括不限於：</Text>
                          <Text m={{ l: "1rem" }}>
                            <ul
                              style={{
                                listStylePosition: "outside",
                                paddingLeft: "0px",
                              }}
                            >
                              <li>經由您書面同意。</li>
                              <li>法律明文規定。</li>
                              <li>為免除您生命、身體、自由或財產上之危險。</li>
                              <li>
                                與公務機關或學術研究機構合作，基於公共利益為統計或學術研究而有必要，且資料經過提供者處理或蒐集者依其揭露方式無從識別特定之當事人。
                              </li>
                              <li>
                                當您在網站的行為，違反服務條款或可能損害或妨礙網站與其他使用者權益或導致任何人遭受損害時，經網站管理單位研析揭露您的個人資料是為了辨識、聯絡或採取法律行動所必要者。
                              </li>
                              <li>有利於您的權益。</li>
                              <li>
                                本網站委託廠商協助蒐集、處理或利用您的個人資料時，將對委外廠商或個人善盡監督管理之責。
                              </li>
                            </ul>
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            六、Cookie 之使用
                          </Text>
                          <Text>
                            為了提供您最佳的服務，本網站會在您的電腦中放置並取用我們的Cookie，若您不願接受
                            Cookie的寫入，您可在您使用的瀏覽器功能項中設定隱私權等級為高，即可拒絕Cookie
                            的寫入，但可能會導致網站某些功能無法正常執行。
                          </Text>
                          <Text textWeight="600" m={{ t: "1rem", b: "0.5rem" }}>
                            七、隱私權保護政策之修正
                          </Text>
                          <Text>
                            本網站隱私權保護政策將因應需求隨時進行修正，修正後的條款將刊登於網站上。
                          </Text>
                        </Div>
                      </Div>
                    </SmallModal>
                  </Div>
                </Col>
              </Row>
            </Div>
          </Div>
        </Col>
      </Row>
    </Div>
  );
};

export default Footer;
