import {
  Button,
  Col,
  Div,
  Icon,
  Image,
  Notification,
  Row,
  Tag,
  Text,
} from "atomize";
import React, { useEffect, useRef, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import NavBar from "./components/NavBar";
import Btn from "./components/Btn";
import InputField from "./components/InputField";
import InputTextarea from "./components/InputTextarea";
import Footer from "./components/Footer";
// import banner1 from "./assets/images/banner0416.png";
// import banner2 from "./assets/images/pet-banner-1.jpg";
// import banner3 from "./assets/images/pet-banner-2.jpg";
import sloganBg from "./assets/images/bg/gogomaumau_slogan_bg.png";
// import situation1 from "./assets/images/situation1.png";
// import situation2 from "./assets/images/situation2.png";
// import situation3 from "./assets/images/situation3.png";
import doctor from "./assets/images/doctor.png";
import pointerCircle from "./assets/images/pointer_circle.png";
import pointer from "./assets/images/pointer.png";
import storyCustomer1 from "./assets/images/profile/customer1.png";
import storyCustomer2 from "./assets/images/profile/customer2.png";
import storyCustomer3 from "./assets/images/profile/customer3.png";
import joseph from "./assets/images/profile/Joseph.png";
import cindy from "./assets/images/profile/Cindy.png";
import emily from "./assets/images/profile/Emily.png";
import hailey from "./assets/images/profile/Hailey.png";
import jess from "./assets/images/profile/Jess.png";
import joey from "./assets/images/profile/Joey.png";
import lena from "./assets/images/profile/Lena.png";
import selina from "./assets/images/profile/Selina.png";
// import contactImg from "./assets/images/bg/contact.png";
import { ReactComponent as BannerBtnRight } from "./assets/icon/banner_btn_right.svg";
import { ReactComponent as BannerBtnLeft } from "./assets/icon/banner_btn_left.svg";
import { ReactComponent as PetsPurple } from "./assets/icon/pets_purple.svg";
import { ReactComponent as FeatureIcon1 } from "./assets/icon/feature_icon1.svg";
import { ReactComponent as FeatureIcon2 } from "./assets/icon/feature_icon2.svg";
import { ReactComponent as FeatureIcon4 } from "./assets/icon/feature_icon4.svg";
import useDocumentTitle from "./components/useDocumentTitle";
import { ConfigProvider, Table } from "antd";
import 中科 from "./assets/images/logo/中科.png";
import 台大創創 from "./assets/images/logo/台大創創.png";
import 工研院 from "./assets/images/logo/工研院.png";
import 新北創立坊 from "./assets/images/logo/新北創立坊.png";
import ReCAPTCHA from "react-google-recaptcha";

const SituationCard = ({ situation, text, title, content }) => (
  <Div
    border={{ xs: "0px solid", md: "2px solid" }}
    borderColor="#79384A"
    rounded="xl"
    d="flex"
    flexDir={{ xs: "column", md: "row" }}
    w="100%"
    h="100%"
  >
    {/* 圖片 */}
    <Div
      border={{ xs: "2px solid", md: "0px solid" }}
      borderColor="#79384A"
      rounded={{ l: "xl", r: { xs: "xl", md: "0", lg: "0" } }}
      bgImg={situation}
      bgPos="center"
      bgSize="cover"
      d="flex"
      justify="center"
      align="center"
      maxW="446px"
      w="100%"
      h={{ xs: "18.1rem", md: "18.1rem", lg: "auto" }}
    />
    {/* 情境文字 */}
    <Div
      m={{
        x: { xs: "0rem", md: "2rem", lg: "4.5rem" },
        y: { xs: "0.75rem", md: "1rem", lg: "2rem" },
      }}
    >
      <Div
        d="flex"
        flexDir={{ xs: "column", lg: "row" }}
        justify="start"
        align={{ xs: "center", sm: "start", md: "start", lg: "center" }}
      >
        <Tag
          bg="#79384A"
          textColor="white"
          textWeight="900"
          p={{ x: "1.25rem", y: "0.8rem" }}
          m={{
            r: { xs: "0rem", md: "0.6rem", lg: "1.3rem" },
            t: { sm: "1.2rem", lg: "0rem" },
          }}
          textSize={{ xs: "12px", lg: "16px" }}
          rounded="circle"
        >
          {text}
        </Tag>
        <Text
          textSize="heading2"
          textWeight="900"
          textColor="#242424"
          //m={{ t: { xs: "0.75rem", lg:"1.5rem" } }}
          m={{ t: { xs: "0.75rem", md: "1rem", lg: "1.5rem" } }}
        >
          {title}
        </Text>
      </Div>
      <Text
        textSize="header"
        textWeight="900"
        textColor="#595959"
        m={{ t: { xs: "0rem", sm: "0rem", md: "0rem", lg: "2rem" } }}
        style={{ lineHeight: "2rem" }}
      >
        {content}
      </Text>
    </Div>
  </Div>
);

const FeatureCard = ({ opacity, icon, title, text1, text2, text3 }) => (
  <Div
    border="4px solid"
    borderColor="#79384A"
    hoverBorderColor="#F3B23E"
    rounded="16px"
    bg="#ffffff"
    shadow="3"
    hoverShadow="3"
    opacity={opacity}
    minW={{ xs: "13.5rem", md: "auto" }}
    align="start"
    m={{ t: "2rem" }}
  >
    <Div
      d="flex"
      flexDir="column"
      justify="start"
      align="center"
      m={{ x: "0.5rem", y: "3.5rem" }}
    >
      <Div>{icon}</Div>
      <Div
        d="flex"
        flexDir="column"
        justify="center"
        align="center"
        m={{ t: "1.5rem" }}
      >
        <Text
          textSize="heading2"
          textWeight="900"
          textColor="#242424"
          textAlign="center"
        >
          {title}
        </Text>
        <Div m={{ x: "1.5rem" }}>
          <Text
            textSize="subheading1"
            textWeight="400"
            textColor="#595959"
            m={{ t: "0.75rem", b: "0rem" }}
            textAlign="start"
          >
            <ul style={{ listStylePosition: "outside", paddingLeft: "8px" }}>
              <li>{text1}</li>
              <li>{text2}</li>
              {text3}
            </ul>
          </Text>
        </Div>
      </Div>
    </Div>
  </Div>
);

const featureCardRwdStyle = {
  marginTop: "0rem",
  display: "flex",
  height: "100%",
};

const StoryCard = ({ portfile, name, location, text }) => (
  <Div bg="#ffffff" p="2rem" rounded="xl" shadow="3" hoverShadow="3">
    <Div d="flex" flexDir="row">
      <Div
        bgImg={portfile}
        bgSize="cover"
        bgPos="center"
        rounded="circle"
        w="48px"
        h="48px"
        m={{ r: "1rem" }}
      />
      <Div>
        <Text textSize="subheading1" textWeight="600" textColor="#555555">
          {name}
        </Text>
        <Text textSize="subheading1" textWeight="600" textColor="#A6A4A1">
          {location}
        </Text>
      </Div>
    </Div>
    <Div m={{ t: "1.5rem" }}>
      <Text textSize="subheading1" textWeight="900" textColor="#242424">
        {text}
      </Text>
    </Div>
  </Div>
);

const storyCardRwdStyle = {
  marginTop: "3rem",
  height: "22rem",
};

const MemberCard = ({ portfile, name, position, experience }) => {
  const [isHovering, setIsHovering] = useState(false);

  return (
    <Div
      border="4px solid"
      borderColor="#79384A"
      rounded="16px"
      bg="#ffffff"
      shadow="3"
      //maxW="21rem"
      // minW={{ xs: "17rem", md: "auto" }}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      cursor="pointer"
      //m={{r: "1rem"}}
    >
      {isHovering ? (
        <Div
          d="flex"
          flexDir="column"
          align="center"
          justify="center"
          bg="#79384A"
          textColor="white"
          p={{ x: "1rem", y: "3rem" }}
          hoverShadow="3"
          h="18rem"
          rounded="10px"
          transition
        >
          <Text textSize="subheading1" textWeight="600" textAlign="center">
            {experience}
          </Text>
        </Div>
      ) : (
        <Div
          d="flex"
          flexDir="column"
          justify="center"
          align="center"
          p={{ x: "1rem", y: "3rem" }}
        >
          <Div
            bgImg={portfile}
            bgSize="cover"
            bgPos="center"
            rounded="circle"
            w="100px"
            h="100px"
            m={{ r: "1rem" }}
          />
          <Div
            d="flex"
            flexDir="column"
            justify="center"
            align="center"
            m={{ t: "1.5rem" }}
          >
            <Text textSize="heading2" textWeight="900" textColor="#242424">
              {name}
            </Text>
            <Div>
              <Text
                textSize="subheading1"
                textWeight="400"
                textColor="#595959"
                m={{ t: "0.75rem", b: "0rem" }}
                textAlign="center"
              >
                {position}
              </Text>
            </Div>
          </Div>
        </Div>
      )}
    </Div>
  );
};

const memberCardRwdStyle = {
  marginTop: "3rem",
  height: "23rem",
};

const Gogomaumua = () => {
  useDocumentTitle("科學毛怪 PetSci - 讓生物資訊走入你我生活");
  const [isLoading, setIsLoading] = useState(true);

  const handleImageLoad = () => {
    setIsLoading(false);
  };

  // nav bar 描點
  const scrollToSection = ({ id }) => {
    const toSection = document.getElementById(id);
    if (toSection) {
      toSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  //float button
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  //Carousel arrow
  const bannerPrevRef = useRef(null);
  const bannerNextRef = useRef(null);
  const storyPrevRef = useRef(null);
  const storyNextRef = useRef(null);
  const memberPrevRef = useRef(null);
  const memberNextRef = useRef(null);

  //table
  const [visibleRows, setVisibleRows] = useState(4); //起始顯示4行

  const columns = [
    {
      dataIndex: "name",
      key: "name",
      render: (text, record) => (
        <a href={record.url} target="_blank" rel="noopener noreferrer">
          <Text
            textSize={{ xs: "16px", md: "20px" }}
            textWeight="500"
            textColor="#555555"
            cursor={record.url == null ? "none" : "pointer"}
            hoverTextColor={record.url == null ? "#555555" : "#79384A"}
          >
            {text}
          </Text>
        </a>
      ),
    },
    {
      dataIndex: "prize",
      key: "prize",
      render: (text) => (
        <Text
          textSize={{ xs: "16px", md: "20px" }}
          textWeight="600"
          textColor="#555555"
        >
          {text}
        </Text>
      ),
    },
    {
      dataIndex: "date",
      key: " date",
      render: (text) => (
        <Text
          textSize={{ xs: "16px", md: "20px" }}
          textWeight="600"
          textColor="#555555"
        >
          {text}
        </Text>
      ),
    },
  ];

  const dataSource = [
    {
      name: "教育部精準健康產業跨領域人才培育計畫",
      url: "https://sites.google.com/gs.ncku.edu.tw/113year/%E9%A6%96%E9%A0%81",
      prize: "績優團隊",
      date: "2024",
    },
    {
      name: "臺灣大學創創中心 G19 車庫孵化器",
      url: "https://tec.ntu.edu.tw/2023/05/30/2023春季新創團隊速看：科學毛怪",
      prize: "獲選進駐",
      date: "2023",
    },
    {
      name: "國科會 FITI 創新創業激勵計畫",
      url: "https://udn.com/news/story/7238/7612740",
      prize: "獲選第一階段 17 強",
      date: "2023",
    },
    {
      name: "數發部資訊應用服務創新創業新秀選拔",
      url: "https://newtalent.tca.org.tw/Home/AwardTeam?teamid=1072",
      prize: "優質新創組 銀牌",
      date: "2023",
    },
    {
      name: "桃園市新創之星 X 創天下競賽",
      url: "https://www.chinatimes.com/realtimenews/20230608004516-260405?chdtv",
      prize: "科技應用組 金獎",
      date: "2023",
    },
    {
      name: "武漢金銀湖盃海峽兩岸青年創新創業大賽",
      url: "https://www.chinatimes.com/campus/20230825004227-262301?chdtv",
      prize: "三等獎",
      date: "2023",
    },
    {
      name: "新北市新北創力坊 InnoSquare 第 19 期",
      prize: "獲選進駐",
      date: "2023",
    },
    {
      name: "教育部全國精準健康產業創新創業競賽",
      prize: "智慧健康跨域組 銀獎",
      date: "2023",
    },
    {
      name: "臺北醫學大學全國生醫創新創業競賽",
      prize: "金獎",
      date: "2023",
    },
    {
      name: "iThome 技術系列文章鐵人賽",
      url: "https://ithelp.ithome.com.tw/users/20151510/ironman/5598",
      prize: "AI & DATA 組 佳作",
      date: "2022",
    },
  ];

  const showMore = () => {
    //點擊按鈕時顯示dataSource全
    setVisibleRows(dataSource.length);
  };
  const showLess = () => {
    //點擊按鈕時顯示dataSource全
    setVisibleRows(4);
  };
  const dataSourceToShow = dataSource.slice(0, visibleRows); //顯示[0]~[visibleRows]

  const reports = [
    {
      portfile: storyCustomer3,
      name: "飼主 顏女士",
      location: "貓咪 / 1歲 / ♂",
      text: "好處是可以更瞭解自己毛孩目前的身體健康狀態，越清楚身體的狀況才可以提早預防疾病！！ 結果就是左圖 (好讀版報告)！我個人覺得超可愛啊啊啊啊啊啊",
    },
    {
      portfile: storyCustomer2,
      name: "飼主 嘎嘎",
      location: "貓咪 / 11歲 / ♀",
      text: "天呀! 有這麼多壞菌。覺得裡面的圖很可愛，不一樣菌種角色標示重點我覺得很好。透過角色可以連結到為什麼毛孩會嘔吐。",
    },
    {
      portfile: storyCustomer1,
      name: "飼主 Brook",
      location: "貓咪 / 1 歲 / ♀",
      text: "可以從報告中圖片來判斷好壞菌，所以很容易看出來她是否好菌，每個名稱都有親切的描述。不像血檢裡面都密密麻麻很多文字，當我發現自己看得懂的時候覺得很棒。",
    },
    {
      portfile: storyCustomer2,
      name: "飼主 杜女士",
      location: "貓咪 / 1 歲 / ♀",
      text: "覺得腸毛相有趣的點是沒想過要將菌檢測要用在寵物身上，本身是相關科系的人所以有點了解。也好奇寵物有什麼菌，看到價錢覺得還算便宜，因為知道定序很貴。",
    },
    {
      portfile: storyCustomer3,
      name: "飼主 賴女士",
      location: "狗狗 / 9歲 / ♀",
      text: "因為狗狗老年，所以想要多照顧他。第一次看到寵物腸道菌叢分析，是相關領域所以知道很重要。",
    },
  ];

  const members = [
    {
      portfile: joseph,
      name: "Joseph",
      position: "CEO",
      experience: (
        <>
          NTU Double Master’s degree
          <br />
          生物科技
          <br />
          生醫電子與資訊學
        </>
      ),
    },
    {
      portfile: cindy,
      name: "Cindy",
      position: "COO",
      experience: (
        <>
          NTNU Bachelor’s degree
          <br />
          社會教育
        </>
      ),
    },
    {
      portfile: emily,
      name: "Emily",
      position: "CTO",
      experience: (
        <>
          NYCU Master’s degree
          <br />
          生物醫學資訊
        </>
      ),
    },
    {
      portfile: joey,
      name: "Joey",
      position: "BD",
      experience: (
        <>
          National Defense Medical Center Master’s degree
          <br />
          微生物及免疫學
        </>
      ),
    },
    {
      portfile: lena,
      name: "Lena",
      position: "UIUX Designer",
      experience: (
        <>
          NTU Bachelor’s degree
          <br />
          生物產業傳播暨發展
        </>
      ),
    },
    {
      portfile: hailey,
      name: "Hailey",
      position: "UIUX Designer",
      experience: (
        <>
          NTNU Bachelor’s degree
          <br />
          使用者體驗設計
        </>
      ),
    },
    {
      portfile: selina,
      name: "Selina",
      position: "Frontend Engineer",
      experience: (
        <>
          NCUE Bachelor’s degree
          <br />
          資訊管理
        </>
      ),
    },
    {
      portfile: jess,
      name: "Jess",
      position: "Backend Engineer",
      experience: (
        <>
          SCU Bachelor’s degree
          <br />
          後端工程
        </>
      ),
    },
  ];

  const [reportSlides, setReportSlides] = useState([]);
  const [memberSlides, setMemberSlides] = useState([]);

  useEffect(() => {
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      const isTablet = window.innerWidth >= 768 && window.innerWidth <= 1024;
      const reportCardsPerSlide = isMobile ? 1 : isTablet ? 2 : 3;
      const memberCardsPerSlide = isMobile ? 1 : isTablet ? 2 : 3;
      const newReportSlides = [];
      const newMemberSlides = [];

      for (let i = 0; i < reports.length; i += reportCardsPerSlide) {
        newReportSlides.push(reports.slice(i, i + reportCardsPerSlide));
      }
      for (let i = 0; i < members.length; i += memberCardsPerSlide) {
        newMemberSlides.push(members.slice(i, i + memberCardsPerSlide));
      }

      setReportSlides(newReportSlides);
      setMemberSlides(newMemberSlides);
    };

    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [comment, setComment] = useState("");

  const handleChange = (e) => {
    const { name, value } = e.target;

    switch (name) {
      case "name":
        setName(value);
        break;
      case "email":
        setEmail(value);
        break;
      case "comment":
        setComment(value);
        break;
      default:
      // 可以处理默认情况或者不处理
    }
  };

  const [showMessage, setShowMessage] = useState(false);
  const [showNotification, setShowNotification] = useState(false);
  const [showErrorNotification, setShowErrorNotification] = useState(false);

  const recaptchaRef = useRef();
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY;
  const [recaptchaCheck, setRecaptchaCheck] = useState(false);
  const handleRecaptchaChange = () => {
    setRecaptchaCheck(true);
  };

  const apiurl = `${process.env.REACT_APP_API_URL}/users/contact-us`;
  const handleSubmit = () => {
    // alert(name);
    // alert(email);
    // alert(comment);
    //成功送出(不能輸入空白字符)
    //alert("false", recaptchaCheck)
    if (email.trim().length > 0 && recaptchaCheck == true) {
      // alert("send");
      // alert("true", recaptchaCheck);
      fetch(apiurl, {
        method: "POST",
        headers: {
          accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          comment: comment,
          verified: "True",
        }),
      })
        .then((response) => {
          if (response.status === 201) {
            setShowNotification(true);
            // 设置定时器以在几秒后隐藏通知
            setTimeout(() => {
              setShowNotification(false);
            }, 3000); // 3000 毫秒后关闭通知
          } else {
            setShowErrorNotification(true);
            setTimeout(() => {
              setShowErrorNotification(false);
            }, 3000);
            return response.json();
          }
        })
        .then((data) => {
          //console.log("data:", data);
        })
        .catch((error) => {
          console.error("Error:", error);
        });

      setName("");
      setEmail("");
      setComment("");
      setShowMessage(false);
      setRecaptchaCheck(false);
      if (recaptchaRef.current) {
        recaptchaRef.current.reset();
      }
    }
    //無法送出，需必填
    else {
      //alert("error");
      setShowMessage(true);
      setShowNotification(false);
      setShowErrorNotification(true);
    }
  };

  return (
    <>
      <Div d={isLoading ? "flex" : "none"} justify="center" align="center">
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      </Div>

      <Div d={isLoading ? "none" : ""}>
        <NavBar
          logoClick={() => scrollToTop()}
          techClick={() => scrollToSection({ id: "tech" })}
          reportsClick={() => scrollToSection({ id: "reports" })}
          contactClick={() => scrollToSection({ id: "contact" })}
        />

        {/* 倫波 */}
        <Div id="feature" pos="relative" zIndex="2" m={{ t: "80px" }}>
          {/* <Div
          ref={bannerPrevRef}
          pos="absolute"
          zIndex="3"
          top="50%"
          transform="translateY(-50%)"
          left="4rem"
          d={{ xs: "none", md: "flex" }}
          cursor="pointer"
        >
          <BannerBtnLeft />
        </Div>
        <Div
          ref={bannerNextRef}
          pos="absolute"
          zIndex="3"
          top="50%"
          transform="translateY(-50%)"
          right="4rem"
          d={{ xs: "none", md: "flex" }}
          cursor="pointer"
        >
          <BannerBtnRight />
        </Div> */}

          <Swiper
            modules={[Autoplay, Pagination, Navigation]}
            spaceBetween={0}
            slidesPerView={1}
            crossFade="true"
            loop={true}
            autoplay={{
              delay: 5500,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
              renderBullet: (index, className) => {
                return (
                  '<span class="' +
                  className +
                  '" style="background-color: #F3B23E"></span>'
                );
              },
            }}
            navigation={{
              prevEl: bannerPrevRef.current,
              nextEl: bannerNextRef.current,
            }}
            onBeforeInit={(swiper) => {
              swiper.params.navigation.prevEl = bannerPrevRef.current;
              swiper.params.navigation.nextEl = bannerNextRef.current;
            }}
          >
            <SwiperSlide>
              <Div>
                <Image
                  src="https://i.imgur.com/TD4DDnS.jpeg"
                  onLoad={handleImageLoad}
                />
              </Div>
            </SwiperSlide>
            {/* <SwiperSlide>
            <Div>
              <Image src={banner2} />
            </Div>
          </SwiperSlide>
          <SwiperSlide>
            <Div>
              <Image src={banner3} />
            </Div>
          </SwiperSlide> */}
          </Swiper>
        </Div>

        {/* 品牌介紹 */}
        <Div
          id="intro"
          bg="#ffffff"
          bgImg={sloganBg}
          bgSize="cover"
          bgPos="center"
          p={{
            t: { xs: "2rem", sm: "5rem", lg: "7rem", xl: "11rem" },
            b: { xs: "6.5rem", xl: "15rem" },
            x: { xs: "2rem", lg: "10rem", xl: "23rem" },
          }}
        >
          <Div d="flex" flexDir="row" justify="start" align="start">
            <Div d="flex" flexDir="column" justify="start" align="start">
              <Div
                d="flex"
                flexDir="column"
                justify={{ xs: "center", xl: "start" }}
                align={{ xs: "center", xl: "start" }}
              >
                <Div d="flex" flexDir="row" flexWrap="nowrap">
                  <Text
                    textSize={{ xs: "heading2", lg: "heading1" }}
                    textWeight="900"
                    textColor="#242424"
                  >
                    毛孩
                    <Text
                      textSize={{ xs: "heading2", lg: "heading1" }}
                      textWeight="900"
                      textColor="#F3B23E"
                      d="flex"
                      flexDir="row"
                      display="inline-flex"
                    >
                      科學化分析
                    </Text>
                    第一品牌
                  </Text>
                </Div>

                <Div
                  m={{ t: "1.5rem", b: "4rem" }}
                  d="flex"
                  flexDir="row"
                  flexWrap="nowrap"
                >
                  <Text
                    textSize="body1"
                    textWeight="400"
                    textColor="#555555"
                    style={{ lineHeight: "1.75rem" }}
                  >
                    我們提供
                    <Text
                      textSize="body1"
                      textWeight="900"
                      textColor="#555555"
                      d="inline-flex"
                    >
                      寵物腸道菌檢測分析
                    </Text>
                    ，以寵物糞便進行腸道菌相檢測，藉由 AI
                    及最佳化運算技術，給予寵物最適合的飲食建議，並與臺灣在地廠商販售寵物食品，提供飼主選購，以科學化方式改善寵物健康狀態。
                  </Text>
                </Div>

                <Div
                  w="14.5rem"
                  d="flex"
                  justify="center"
                  align="center"
                  onClick={() =>
                    window.open(
                      "https://www.zeczec.com/projects/petsci-gut-mamoth/?utm_source=website&utm_medium=website&utm_campaign=zec",
                      "_blank"
                    )
                  }
                >
                  <Btn text="申請檢測"></Btn>

                </Div>
                
              </Div>
            </Div>
          </Div>
        </Div>

        {/* 狀況提點 */}
        <Div
          id="situation"
          d="flex"
          flexDir="column"
          justify="center"
          align="center"
          p={{
            b: { xs: "6.5rem", xl: "10rem" },
            x: { xs: "2rem", xl: "14.25rem" },
          }}
        >
          <Div>
            <Text
              textSize={{ xs: "heading2", lg: "heading1" }}
              textWeight="900"
              textColor="#242424"
            >
              你的寵物會不會出現以下情況?
            </Text>
          </Div>

          <Div m={{ t: { xs: "3rem", lg: "4.5rem" } }} w="100%">
            <SituationCard
              situation="https://i.imgur.com/MVSeAEP.png"
              text="情境一"
              title="寵物常常拉肚子和嘔吐"
              content="嗚～怎麼又拉又吐了！是亂吃還是亂舔呀？已經看好幾次獸醫了..."
            />
          </Div>
          <Div m={{ t: "3.5rem" }} w="100%">
            <SituationCard
              situation="https://i.imgur.com/nyGpWpW.png"
              text="情境二"
              title="不確定寵物的健康狀況"
              content="嗯...不知道現在佛系餵養的飼料和保健品，對寵物到底健不健康？"
            />
          </Div>
          <Div m={{ t: "3.5rem" }} w="100%">
            <SituationCard
              situation="https://i.imgur.com/oRhnZhj.png"
              text="情境三"
              title="擔心寵物營養不均衡"
              content="寵物年紀越來越大了，好擔心牠會營養不均衡，要對牠更好才行！"
            />
          </Div>

          <Div m={{ t: "2.25rem", b: "2.25rem" }}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="88"
              viewBox="0 0 12 88"
              fill="none"
            >
              <path
                d="M6 88L8.88675 83H3.11325L6 88ZM5.5 0V2H6.5V0L5.5 0ZM5.5 6L5.5 10H6.5L6.5 6H5.5ZM5.5 14V18H6.5V14H5.5ZM5.5 22V26H6.5V22H5.5ZM5.5 30V34H6.5V30H5.5ZM5.5 38V42H6.5V38H5.5ZM5.5 46V50H6.5L6.5 46H5.5ZM5.5 54V58H6.5V54H5.5ZM5.5 62L5.5 66H6.5V62H5.5ZM5.5 70V74H6.5V70H5.5ZM5.5 78L5.5 82H6.5L6.5 78H5.5ZM6 88L11.7735 78H0.226497L6 88ZM5 0V2H7V0L5 0ZM5 6L5 10H7L7 6H5ZM5 14V18H7V14H5ZM5 22V26H7V22H5ZM5 30V34H7V30H5ZM5 38V42H7L7 38H5ZM5 46L5 50H7L7 46H5ZM5 54V58H7V54H5ZM5 62V66H7V62H5ZM5 70V74H7V70H5ZM5 78L5 82H7V78H5Z"
                fill="#F3B23E"
              />
            </svg>
          </Div>

          <Div d="flex" flexDir="row" justify="center" align="center">
            <Div
              d="flex"
              flexDir={{ xs: "column", md: "row" }}
              justify="center"
              align="center"
            >
              <Image
                src={doctor}
                w="11rem"
                d={{ xs: "none", md: "flex" }}
                m={{ r: "2.5rem" }}
              />
              <Div d="flex" flexDir="column" justify="center" align="center">
                <Text
                  textSize={{ xs: "24px", lg: "heading1" }}
                  textColor="#555555"
                  textWeight="900"
                  d={{ xs: "none", md: "flex" }}
                  style={{ lineHeight: "3rem" }}
                >
                  \ 莫驚慌莫害怕，讓『腸毛相』來幫你！ /
                </Text>
                <Text
                  textSize={{ xs: "24px", lg: "heading1" }}
                  textColor="#555555"
                  textWeight="900"
                  textAlign="center"
                  d={{ xs: "flex", md: "none" }}
                  style={{ lineHeight: "2rem" }}
                >
                  莫驚慌莫害怕，
                  <br />
                  讓『腸毛相』來幫你！
                </Text>
                <Div
                  bg="#F3B23E"
                  rounded="xl"
                  border="2px solid"
                  borderColor="#242424"
                  d="flex"
                  justify="center"
                  align="center"
                  shadow="5"
                  p={{ x: "2rem", y: { xs: "1.25rem", md: "0.75rem" } }}
                  textAlign="center"
                  m={{ t: "1.5rem" }}
                  cursor="pointer"
                >
                  <a href="" target="_blank" rel="noopener noreferrer">
                    <Div
                      d="flex"
                      align="center"
                      onClick={() =>
                        window.open(
                          "https://www.zeczec.com/projects/petsci-gut-mamoth/?utm_source=website&utm_medium=website&utm_campaign=zec",
                          "_blank"
                        )
                      }
                    >
                      <Text
                        textSize={{ xs: "24px", lg: "heading1" }}
                        textColor="#242424"
                        textWeight="900"
                        cursor="pointer"
                      >
                        『立即申請』
                      </Text>
                      <Image
                        src={pointerCircle}
                        w="4rem"
                        m={{ l: "1rem" }}
                        d={{ xs: "none", md: "flex" }}
                      />
                    </Div>
                  </a>
                </Div>
              </Div>

              <Div
                d={{ xs: "flex", md: "none" }}
                w="100%"
                justify="space-between"
                m={{ t: "-1.8rem", l: "-2rem" }}
              >
                <Image src={doctor} w="8rem" />
                <Image src={pointer} w="4rem" h="4rem" />
              </Div>
            </Div>
          </Div>
        </Div>

        {/* 核心技術 */}
        <Div
          id="tech"
          bg="rgba(243, 178, 62, 0.10)"
          justify="center"
          align="center"
          p={{
            b: { xs: "6.5rem", xl: "5.5rem" },
            x: { xs: "2rem", xl: "14.25rem" },
            y: { xs: "3.5rem", xl: "5.5rem" },
          }}
        >
          <Div d="flex" flexDir="column" justify="center" align="center">
            <Div
              d="flex"
              flexDir="column"
              justify="center"
              align="center"
              m={{ x: { xs: "0rem", md: "3.5rem", lg: "7rem" }, t: "3rem" }}
            >
              <Text
                textSize={{ xs: "heading2", lg: "heading1" }}
                textWeight="900"
                textColor="#242424"
              >
                核心技術
              </Text>
              <Text
                textSize={{ xs: "reportHeading4", lg: "subheading1" }}
                textWeight="600"
                textColor="#595959"
                m={{ t: { xs: "0.5rem", lg: "1.5rem" } }}
                textAlign="center"
              >
                將人類醫療分析技術應用於毛孩健康，擁有嚴謹技術與專業資訊設計，給予飼主最容易理解專業報告。你是充滿好奇心想深入探究的飼主嗎?
                歡迎點擊按鈕了解我們的技術原理。
              </Text>
            </Div>
          </Div>

          <Div>
            <Div d="flex" flexDir="column" justify="center" align="center">
              <Div
                d="flex"
                flexDir="row"
                justify="center"
                align="center"
                m={{ t: { xs: "0rem", md: "2.5rem" } }}
                zIndex="2"
                flexWrap="wrap"
              >
                <Row d="flex">
                  <Col size={{ xs: 12, md: 4 }} flexGrow="1">
                    <Div style={featureCardRwdStyle}>
                      <FeatureCard
                        icon={<FeatureIcon1 />}
                        title="人醫等級儀器與分類"
                        text1="嚴選人類醫療相同品質的第三代 Oxford Nanopore 定序儀器。"
                        text2="採 AI 降噪搭配高解析度分類器，進行 NCBI 與 SILVA 資料庫比對，精準分類糞便中微生物物種。"
                      />
                    </Div>
                  </Col>
                  <Col size={{ xs: 12, md: 4 }} flexGrow="1">
                    <Div
                      m={{ t: { xs: "2.5rem", md: "0rem" } }}
                      style={featureCardRwdStyle}
                    >
                      <FeatureCard
                        icon={<FeatureIcon2 />}
                        title="嚴謹的科研分析流程"
                        text1="最新整合型精準菌相分析流程。"
                        text2={
                          <>
                            第一作者發表論文於國際學術期刊 IEEE/ACM Transactions
                            on Computational Biology and Bioinformatics
                            <br />
                            (Hu, Zhao-Qi, et al., 2024)
                          </>
                        }
                        text3={
                          <>
                            <li>中華民國發明專利申請中。</li>
                          </>
                        }
                      />
                    </Div>
                  </Col>
                  <Col size={{ xs: 12, md: 4 }} flexGrow="1">
                    <Div
                      m={{ t: { xs: "2.5rem", md: "0rem" } }}
                      style={featureCardRwdStyle}
                    >
                      <FeatureCard
                        icon={<FeatureIcon4 />}
                        title="AI 個寵化飲食營養評估"
                        text1="採科研最常用的 KEGG 酵素與代謝資料庫進行比對串接。"
                        text2="AI 技術運用於序列、代謝途徑、營養素與食物缺乏評估，甚至是報告的產出，時時更新犬貓標準菌相，確保報告高準確與高效率產出。"
                      />
                    </Div>
                  </Col>
                </Row>
              </Div>

              <Div
                w="14.5rem"
                d="flex"
                m={{ t: "4rem" }}
                onClick={() =>
                  window.open(
                    "https://medium.com/@petsci/%E8%85%B8%E6%AF%9B%E7%9B%B8-%E6%9C%8D%E5%8B%99%E5%85%A7%E5%AE%B9%E8%88%87%E7%8D%A8%E5%AE%B6%E6%8A%80%E8%A1%93%E4%BB%8B%E7%B4%B9-d6b566c8fd68?utm_source=Bussiness+Web&utm_medium=Bussiness+Web&utm_campaign=Bussiness+Web",
                    "_blank"
                  )
                }
              >
                <Btn text="了解更多"></Btn>
              </Div>
            </Div>
          </Div>
        </Div>

        {/* 獲獎經歷 */}
        <Div
          id="awards"
          justify="center"
          align="center"
          p={{
            b: { xs: "6.5rem", xl: "5.5rem" },
            x: { xs: "2rem", xl: "14.25rem" },
            y: { xs: "3.5rem", xl: "5.5rem" },
          }}
        >
          <Div d="flex" flexDir="column" justify="center" align="center">
            <Div
              d="flex"
              flexDir="column"
              justify="center"
              align="center"
              m={{ x: { xs: "0rem", md: "3.5rem", lg: "7rem" }, t: "3rem" }}
            >
              <Text
                textSize={{ xs: "heading2", lg: "heading1" }}
                textWeight="900"
                textColor="#242424"
              >
                產品獲獎經歷
              </Text>
              <Text
                textSize={{ xs: "reportHeading4", lg: "subheading1" }}
                textWeight="600"
                textColor="#595959"
                m={{ t: { xs: "0.5rem", lg: "1.5rem" } }}
                textAlign="center"
              >
                堅持照顧毛孩健康同時，也在乎飼主報告的閱讀與體驗，我們努力讓科學變得更親近人群，本產品亦獲得許多政府與學校機關的高度肯定。
              </Text>
            </Div>
          </Div>

          <ConfigProvider
            theme={{
              token: {
                colorPrimary: "#79384A",
              },
              components: {
                Table: {
                  headerBg: "#ffffff",
                  headerColor: "#ffffff",
                  borderColor: "#F3B23E",
                  rowHoverBg: "rgba(243, 178, 62, 0.10)",
                  cellFontSize: "16px",
                },
              },
            }}
          >
            <Div bg="#ffffff" align="center" m={{ t: "4.5rem" }}>
              <Table
                columns={columns}
                dataSource={dataSourceToShow}
                showHeader={false}
                pagination={false}
                scroll={{ x: 768 }}
              />

              {visibleRows < dataSource.length ? (
                <Div d="flex" justify="end" align="end" m={{ t: "1.25rem" }}>
                  <Button
                    textSize={{ xs: "16px", md: "20px" }}
                    textWeight="600"
                    textColor="#F3B23E"
                    bg="#ffffff"
                    textDecor="underline"
                    onClick={showMore}
                    cursor="pointer"
                  >
                    顯示更多
                  </Button>
                </Div>
              ) : (
                <Div d="flex" justify="end" align="end" m={{ t: "1.25rem" }}>
                  <Button
                    textSize={{ xs: "16px", md: "20px" }}
                    textWeight="600"
                    textColor="#F3B23E"
                    bg="#ffffff"
                    textDecor="underline"
                    onClick={showLess}
                    cursor="pointer"
                  >
                    收起資訊
                  </Button>
                </Div>
              )}
            </Div>
          </ConfigProvider>

          <Div
            className="responsive-container"
            d="flex"
            flexDir="row"
            justify="start"
            align="center"
            m={{ y: "5.5rem", x: "0rem" }}
          >
            <Div
              minW="7.5rem"
              minH="4.5rem"
              d="flex"
              justify="start"
              align="center"
              m={{ r: "1rem" }}
            >
              <Text
                textSize={{ xs: "header", lg: "heading2" }}
                textWeight="900"
                textColor="#242424"
              >
                合作與協助輔導單位
              </Text>
            </Div>
            <Div
              minW="11.5rem"
              minH="6.5rem"
              border="1px solid"
              borderColor="#A6A4A1"
              rounded="md"
              d="flex"
              justify="center"
              align="center"
              m={{ r: "2rem" }}
            >
              <Image
                src={台大創創}
              />
            </Div>
            <Div
              minW="11.5rem"
              minH="6.5rem"
              border="1px solid"
              borderColor="#A6A4A1"
              rounded="md"
              d="flex"
              justify="center"
              align="center"
              m={{ r: "2rem" }}
            >
              <Image src={工研院}/>
            </Div>
            <Div
              minW="11.5rem"
              minH="6.5rem"
              border="1px solid"
              borderColor="#A6A4A1"
              rounded="md"
              d="flex"
              justify="center"
              align="center"
              m={{ r: "2rem" }}
            >
              <Image src={中科} />
            </Div>
            <Div
              minW="11.5rem"
              minH="6.5rem"
              border="1px solid"
              borderColor="#A6A4A1"
              rounded="md"
              d="flex"
              justify="center"
              align="center"
            >
              <Image
                src={新北創立坊}
              />
            </Div>
          </Div>
        </Div>

        {/* 顧客回饋 */}
        <Div
          id="reports"
          bg="rgba(243, 178, 62, 0.10)"
          p={{
            b: { xs: "6.5rem", xl: "5.5rem" },
            x: { xs: "2rem", xl: "7.25rem" },
            y: { xs: "3.5rem", xl: "5.5rem" },
          }}
          bgSize="cover"
          bgPos="center"
        >
          <Div>
            <Div
              id="feature"
              d="flex"
              flexDir="column"
              justify="center"
              align="center"
            >
              <Div
                d="flex"
                flexDir="column"
                justify="center"
                align="center"
                m={{ x: { xs: "0rem", md: "3.5rem", lg: "7rem" }, t: "3rem" }}
              >
                <Text
                  textSize={{ xs: "heading2", lg: "heading1" }}
                  textWeight="900"
                  textColor="#242424"
                >
                  聽聽我們的顧客怎麼說 📢
                </Text>
              </Div>
            </Div>

            <Div
              pos="relative"
              zIndex="2"
              p={{ x: { md: "3rem", lg: "7rem", xl: "7.5rem" } }}
            >
              <Div
                ref={storyPrevRef}
                pos="absolute"
                zIndex="3"
                top="50%"
                transform="translateY(-50%)"
                left="0rem"
                cursor="pointer"
                d={{ xs: "none", md: "flex" }}
              >
                <BannerBtnLeft />
              </Div>
              <Div
                ref={storyNextRef}
                pos="absolute"
                zIndex="3"
                top="50%"
                transform="translateY(-50%)"
                right="0rem"
                cursor="pointer"
                d={{ xs: "none", md: "flex" }}
              >
                <BannerBtnRight />
              </Div>

              <Swiper
                modules={[Autoplay, Pagination, Navigation]}
                spaceBetween={16}
                slidesPerView={1}
                crossFade="true"
                loop={true}
                autoplay={{
                  delay: 5500,
                  disableOnInteraction: false,
                }}
                pagination={{
                  clickable: true,
                  renderBullet: (index, className) => {
                    return (
                      '<span class="' +
                      className +
                      '" style="background-color: #F3B23E"></span>'
                    );
                  },
                }}
                navigation={{
                  prevEl: storyPrevRef.current,
                  nextEl: storyNextRef.current,
                }}
                onBeforeInit={(swiper) => {
                  swiper.params.navigation.prevEl = storyPrevRef.current;
                  swiper.params.navigation.nextEl = storyNextRef.current;
                }}
              >
                {reportSlides.map((slide, index) => (
                  <SwiperSlide key={index}>
                    <Row d="flex" justify="center" align="center">
                      {slide.map((reports, reportsIndex) => (
                        <Col key={reportsIndex}>
                          <Div style={storyCardRwdStyle}>
                            <StoryCard {...reports} />
                          </Div>
                        </Col>
                      ))}
                    </Row>
                  </SwiperSlide>
                ))}
              </Swiper>
            </Div>
          </Div>
        </Div>

        {/* 團隊成員 */}
        <Div
          id="team"
          justify="center"
          align="center"
          p={{
            b: { xs: "6.5rem", xl: "5.5rem" },
            x: { xs: "2rem", xl: "7.25rem" },
            y: { xs: "3.5rem", xl: "5.5rem" },
          }}
        >
          <Div d="flex" flexDir="column" justify="center" align="center">
            <Div
              d="flex"
              flexDir="column"
              justify="center"
              align="center"
              m={{ x: { xs: "0rem", md: "3.5rem", lg: "7rem" }, t: "3rem" }}
            >
              <Text
                textSize={{ xs: "heading2", lg: "heading1" }}
                textWeight="900"
                textColor="#242424"
              >
                經營團隊
              </Text>
              <Text
                textSize={{ xs: "reportHeading4", lg: "subheading1" }}
                textWeight="600"
                textColor="#595959"
                m={{ t: { xs: "0.5rem", lg: "1.5rem" } }}
                textAlign="center"
              >
                組成多元是我們的特色，由臺灣大學、臺灣師範大學、陽明交通大學等頂尖校友組成，橫跨生物、資訊、教育、介面設計與體驗跨領域技術，一同為毛孩健康把關。
              </Text>
            </Div>
          </Div>

          <Div
            pos="relative"
            zIndex="2"
            p={{ x: { md: "3rem", lg: "7rem", xl: "7rem" } }}
          >
            <Div
              ref={memberPrevRef}
              pos="absolute"
              zIndex="3"
              top="50%"
              transform="translateY(-50%)"
              left="0rem"
              cursor="pointer"
              d={{ xs: "none", md: "flex" }}
            >
              <BannerBtnLeft />
            </Div>
            <Div
              ref={memberNextRef}
              pos="absolute"
              zIndex="3"
              top="50%"
              transform="translateY(-50%)"
              right="0rem"
              cursor="pointer"
              d={{ xs: "none", md: "flex" }}
            >
              <BannerBtnRight />
            </Div>

            <Swiper
              modules={[Autoplay, Pagination, Navigation]}
              spaceBetween={16}
              slidesPerView={1}
              crossFade="true"
              loop={true}
              autoplay={{
                delay: 5500,
                disableOnInteraction: false,
              }}
              pagination={{
                clickable: true,
                renderBullet: (index, className) => {
                  return (
                    '<span class="' +
                    className +
                    '" style="background-color: #F3B23E"></span>'
                  );
                },
              }}
              navigation={{
                prevEl: memberPrevRef.current,
                nextEl: memberNextRef.current,
              }}
              onBeforeInit={(swiper) => {
                swiper.params.navigation.prevEl = memberPrevRef.current;
                swiper.params.navigation.nextEl = memberNextRef.current;
              }}
            >
              {memberSlides.map((slide, index) => (
                <SwiperSlide key={index}>
                  <Row d="flex" justify="center" align="center">
                    {slide.map((member, memberIndex) => (
                      <Col key={memberIndex}>
                        <Div style={memberCardRwdStyle}>
                          <MemberCard {...member} />
                        </Div>
                      </Col>
                    ))}
                  </Row>
                </SwiperSlide>
              ))}
            </Swiper>
          </Div>
        </Div>

        {/* 聯絡我們 */}
        <Div
          id="contact"
          bg="linear-gradient(to bottom right, #FFD88D 0%, rgba(255, 255, 255, 0.60) 41%) bottom right / 50% 50% no-repeat, linear-gradient(to bottom left, #FFD88D 0%, rgba(255, 255, 255, 0.60) 41%) bottom left / 50% 50% no-repeat, linear-gradient(to top left, #FFD88D 0%, rgba(255, 255, 255, 0.60) 41%) top left / 50% 50% no-repeat, linear-gradient(to top right, #FFD88D 0%, rgba(255, 255, 255, 0.60) 41%) top right / 50% 50% no-repeat;"
        >
          <Div
            p={{
              b: { xs: "6.5rem", xl: "5.5rem" },
              x: { xs: "2rem", xl: "14.25rem" },
              y: { xs: "3.5rem", xl: "5.5rem" },
            }}
            d="flex"
            flexGrow={{ xs: "column", md: "row", lg: "row" }}
          >
            <Row>
              <Col size={{ xs: "12", md: "6", lg: "6" }}>
                <Div
                  d="flex"
                  flexDir="column"
                  justify="center"
                  align="start"
                  w={{ xs: "100%", lg: "85%" }}
                  m={{ t: "3rem" }}
                >
                  <Text
                    textSize={{ xs: "heading2", lg: "heading1" }}
                    textWeight="900"
                    textColor="#242424"
                  >
                    聯絡我們 👋🏻
                  </Text>
                  <Text
                    textSize={{ xs: "reportHeading4", lg: "subheading1" }}
                    textWeight="600"
                    textColor="#595959"
                    m={{ t: "1.5rem" }}
                    textAlign="start"
                  >
                    若針對產品有任何問題，或是想要成為我們的合作夥伴，歡迎留下信箱，我們將會進一步與您聯絡～
                  </Text>

                  <Div w="100%" m={{ t: "1.25rem" }}>
                    <InputField
                      label="姓名"
                      placeholder="您的名字"
                      input="name"
                      value={name}
                      onChange={handleChange}
                    />
                  </Div>
                  <Div w="100%" m={{ t: "1.25rem" }}>
                    <InputField
                      label="信箱*"
                      placeholder="您的信箱"
                      input="email"
                      value={email}
                      onChange={handleChange}
                    />
                  </Div>
                  <Div w="100%" m={{ t: "1.25rem" }}>
                    <InputTextarea
                      label="留言"
                      placeholder="您的任何想法..."
                      input="comment"
                      value={comment}
                      onChange={handleChange}
                    />
                  </Div>

                  <Div w="100%" m={{ t: "2rem" }}>
                    <ReCAPTCHA
                      ref={recaptchaRef}
                      sitekey={siteKey}
                      onChange={handleRecaptchaChange}
                    />
                  </Div>
                  <Div w="50%" m={{ t: "2rem" }}>
                    <Button
                      w="100%"
                      rounded="lg"
                      textSize="14px"
                      textColor="#FFFFFF"
                      bg="#79384A"
                      d="flex"
                      justify="center"
                      align="center"
                      textWeight="600"
                      border="1px solid"
                      borderColor="#79384A"
                      hoverTextColor="#79384A"
                      hoverBg="#FFFFFF"
                      hoverBorderColor="#79384A"
                      //p={{ x: {xs:"2.5rem", md:"5rem", xl: "7.5rem"} }}
                      onClick={() => handleSubmit()}
                    >
                      送出
                    </Button>

                    <Div d={showMessage ? "inline-block" : "none"}>
                      <Div d="inline-block">
                        <Icon
                          d="inline-block"
                          name="AlertSolid"
                          size="14px"
                          color="#EB4C27"
                          m={{ r: "0.25rem" }}
                        />
                        <Text
                          d="inline-block"
                          textSize="paragraph"
                          textColor="#EB4C27"
                          textWeight="600"
                        >
                          請確認必填欄位
                        </Text>
                      </Div>

                      <Div d={recaptchaCheck ? "none" : "inline-block"}>
                        <Div d="inline-block">
                          <Icon
                            d="inline-block"
                            name="AlertSolid"
                            size="14px"
                            color="#EB4C27"
                            m={{ r: "0.25rem" }}
                          />
                          <Text
                            d="inline-block"
                            textSize="paragraph"
                            textColor="#EB4C27"
                            textWeight="600"
                          >
                            請勾選機器人驗證
                          </Text>
                        </Div>
                      </Div>
                    </Div>
                  </Div>
                </Div>
              </Col>

              <Col
                size={{ xs: "12", md: "6", lg: "6" }}
                d="flex"
                justify="center"
                align="center"
              >
                <Div d="flex" justify="end" align="center">
                  <Image
                    src="https://i.imgur.com/0jJBkrf.jpeg"
                    w={{ xs: "100%", lg: "100%" }}
                    m={{ t: { xs: "4rem", md: "2rem", lg: "0rem" } }}
                  />
                </Div>
              </Col>
            </Row>
          </Div>
        </Div>

        {/* float button */}
        <Div pos="relative">
          <Div
            d="flex"
            justify="center"
            align="center"
            pos="absolute"
            zIndex="9"
          >
            <Button
              h="3.5rem"
              w="3.5rem"
              bg="#F3B23E"
              border="2px solid"
              borderColor="#FEF9DE"
              hoverBorderColor="#FEF9DE"
              rounded="circle"
              m={{ r: "1rem" }}
              shadow="3"
              pos="fixed"
              right={{ xs: "0.75rem", md: "1.75rem", xl: "2.75rem" }}
              bottom={{ xs: "0.75rem", md: "1.75rem", xl: "2.75rem" }}
              onClick={scrollToTop}
            >
              <Div>
                <Text textSize="12px" textColor="#79384A" textWeight="600">
                  Top
                </Text>
                <PetsPurple />
              </Div>
            </Button>
          </Div>
        </Div>

        {/* notification */}
        <Div d="flex" justify="center" align="center">
          <Notification
            d={showNotification ? "flex" : "none"}
            bg="#E2FCF0"
            textColor="#0A9C55"
            isOpen={showNotification}
            onClose={() => setShowNotification(false)}
          >
            已成功發送留言
          </Notification>

          <Notification
            d={showErrorNotification ? "flex" : "none"}
            bg="#FFE0E0"
            textColor="#F03D3D"
            isOpen={showErrorNotification}
            onClose={() => setShowErrorNotification(false)}
          >
            發生錯誤
          </Notification>
        </Div>

        <Footer
          techClick={() => scrollToSection({ id: "tech" })}
          reportsClick={() => scrollToSection({ id: "reports" })}
          contactClick={() => scrollToSection({ id: "contact" })}
        />
      </Div>
    </>
  );
};

export default Gogomaumua;
